@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');
@import 'variable';
@import 'override';
@import 'util';
@import 'fontawesome/font-awesome';
@import '~pretty-checkbox/src/pretty-checkbox.scss';

body{
	font-family: $base-font;
	font-size: 14px;
	font-weight: 500;
	color: $base-color;
	background-color: $gray-2
}

#app {
	width: 100vw;
	overflow-x: hidden;
}

.popover {
	z-index: 1010; /* A value higher than 1010 that solves the problem */
}

.nav-navigation{
	padding-top: 0;
	padding-bottom: 0;
	// background-color: #2e3957;
	font-weight: 300;
	.navbar-nav{
		.nav-item{
			.nav-link{
				color: #fff;
				padding: 16px 35px;
				@media (max-width: $xl) {
					padding: 16px 15px;
				}
				border-bottom: 2px solid transparent;
				// &.active{
				// 	color: #31cb9e;
				// 	border-color: #31cb9e;
				// }
				i{
					font-size: 22px;
					margin-right: 10px;
				}
			}
		}
	}
}

.nav-sub{
	.nav-item{
		&.active{
			.nav-link{
				color: $dark-color;
				border-bottom: 2px solid #31cb9e;
				font-weight: 500;
			}
		}
		.nav-link{
			cursor: pointer;
			font-weight: 400;
			color: $gray-4;
			padding: 4px 0;
			margin: 0 15px;
			text-transform: capitalize;
		}
	}
	.page-tile{
		color: $dark-color;
		line-height: 31px;
		margin: 0;
	}
}

.token-selector{
	.select-item{
		border: 1px solid transparent;
		cursor: pointer;
		&:hover, &:focus, &.selected{
			border-color: #8bcef1;
			background-color: #def2fd;
		}
	}
}

.select-trading-pair{
	max-height: 400px;
    overflow-y: auto;
}

.dropdown-menu.time-zone{
	width: 300px;
	.timezone-picker, input{
		width: 100%;
		ul{
			background-color: #e6ebec;
		}
	}
}
.table-hourly{
	font-size: 12px;
	margin-top: 20px;
	tr{
		height: 27px;
	}
	td{
		border-top: 0;
		padding: 4px 15px;
		white-space: nowrap;
	}
}

.modal-input{
	.form-control{
		height: auto;
    padding: 11px 10px;
    font-weight: 500;
		color: inherit;
		text-align: right;
	}
	label, .pretty .state label{
		color: $dark-color;
		font-weight: 500;
	}
}
.modal-xl {
	max-width: 90%;
}

.sticky{
		position: -webkit-sticky;
    position: sticky;
    top: 20px;
    // background-color: yellow;
    // padding: 50px;
    // font-size: 20px;
}

.table-exchange{
	.thead-custom{
		background-color: #9ea1aa;
		color: white;
		font-weight: normal !important;
	}
	.limit-width {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100px;
	}
	.min-width-150 {
		min-width: 150px;
	}
}

@media (max-width: 600px) {
	.container-fluid{
		padding: 5px;
	}
	.navbar-brand{
		display: none !important;
	}
	.navbar-right-item{
		padding-left: 0px;
	}

	.sign-form{
		width: 100% !important;
		right: 0px !important;
		z-index: 500;
		// transform: translate3d(-100px, 76px, 0px) !important;
	}
}

#tokenListingTab{
	padding-bottom: 10px;

	.nav-item{
		border-right: 2px solid #c5c5c5;
		padding-right: 13px;
		a.nav-link{
			border: none;
			cursor: pointer;
			color: #d1d1d1;

			&.active{
				color: #373a39;
				font-weight: 700;
			}
		}

		&.add-more{
			border-right: none;
			a{
				color: #31cb9e;
			}
		}
	}
}

.multiple-layer-dropdown{
	.dropdown-submenu {
		position: relative;
	}
	
	.dropdown-submenu .dropdown-menu {
		top: 0;
		left: 100%;
		margin-top: -1px;
	}
}

.fw-500{
	font-weight: 500!important;
}
