/* bootstrap-demo is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */
.bootstrap-demo .dropdown-trigger {
    border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger>span:after {
    font-size: 12px;
    color: #555;
}

.bootstrap-demo .toggle {
    font: normal normal normal 12px/1 FontAwesome;
    color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
    content: "\f067";
}

.bootstrap-demo .toggle.expanded::after {
    content: "\f068";
}

@media only screen and (min-width: 900px) {
    .fixed-on-scroll {
        position: fixed;
        width: calc(58.3333333333% - 38px);
        top: 0;
        left: 30px;
    }
}
