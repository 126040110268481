.small-negative-usd {
    background-color: rgb(255, 230, 230) !important;
}

.medium-negative-usd {
    background-color: rgb(255, 200, 200) !important;
}

.large-negative-usd {
    background-color: rgb(255, 150, 150) !important;
}


.small-positive-usd {
    background-color: rgb(230, 255, 230) !important;
}

.medium-positive-usd {
    background-color: rgb(200, 255, 200) !important;
}

.large-positive-usd {
    background-color: rgb(160, 255, 160) !important;
}

.huge-positive-usd {
    background-color: rgb(110, 255, 110) !important;
}

.gigantic-positive-usd {
    background-color: rgb(0, 255, 0) !important;
}

.icon-text {
    fill: rgb(0, 0, 0);
    font-family: Courier New;
    font-size: 19px;
    white-space: pre;
}

.icon-svg {
    color: black;
    height: 23px;
}
