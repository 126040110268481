.bg-is-new {
    background-color: rgb(200, 255, 200) !important;
}

.bg-is-delete {
    background-color: rgb(255, 200, 200) !important;
}

.more-img {
    background-color: #bbb;
    text-align: center;
    margin-left: -20px;
    display: grid;
    align-items: center;
    font-size: 11px;
    color: white;

}

.overlap-img img,
.more-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    border: 1px solid white;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
}

.overlap-img img:nth-child(n+2) {
    margin-left: -20px;
}
