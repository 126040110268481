img{
  &.icon{ width: 28px; }
}

.dropdown-toggle::after  {
  margin-left: 7px!important;
  border-color:  #9ea1aa!important;
  // border-width: 0 1px 1px 0!important;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}

.dropdown-nav-item {
  border: none!important;
  color: #9ea1aa!important;
  font-weight: normal!important;
  padding: 8px 0!important;
}
