@import "../../../assets/css/variable.scss";

.ReactTable {
    border: none;
    &-header {
        .title {
            color: #141927;
            margin: 0;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 600;
        }
        * {
            font-family: $base-font;
        }
    }
    img {
        margin: 0 10px;
        &.icon {
            width: 30px;
        }
    }
    .rt-thead {
        background-color: $gray-4;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        &.-headerGroups {
            background-color: $gray-4;
        }
        .rt-th {
            padding: 0;
            border-right: none;
            display: flex;
            align-items: center;
            min-height: 50px;

            > div:first-child {
                inline-size: 100px;
                width: 100%;
                word-wrap: break-word;
                line-height: 1.5;
                white-space: normal;
            }
        }
    }
    .rt-tbody {
        .rt-td {
            border-right: none;
            padding: 14px;
            text-align: center;
            // display: flex;
            // align-items: center;
            // justify-content: center;
        }
        .rt-tr-group {
            border-bottom: none;
            .-even {
                background-color: $gray-1;
            }
            .-odd {
                background-color: #fff;
            }
        }
        .row-danger {
            border: 1px solid #febebe;
            background-color: #ffe5e5 !important;
        }
        .row-warning {
            border: 1px solid #eee97d;
            background-color: #fffbe5 !important;
        }
    }
    .pagination-bottom {
        display: flex;
        margin-top: 20px;
        .Table__pagination {
            margin-left: auto;
            > div {
                display: inline-block;
                button {
                    margin-left: 1px;
                    background-color: $gray-1;
                    border: none;
                    padding: 10px 15px;
                    font-family: $base-font;
                    cursor: pointer;
                    &:focus {
                        outline: none;
                    }
                    &:disabled {
                        color: $gray-3;
                        cursor: default;
                    }
                    &.Table__pageButton--active {
                        background-color: $success-color;
                        color: #fff;
                    }
                }
                &.Table__prevPageWrapper,
                &.Table__nextPageWrapper {
                    button:not(:disabled) {
                        color: $success-color;
                    }
                }
            }
        }
    }
    .pagination-top {
        display: flex;
        margin-top: 10px;
        .Table__pagination {
            position: absolute;
            top: -40px;
            > div {
                display: inline-block;
                button {
                    margin-left: 1px;
                    background-color: $gray-1;
                    border: none;
                    padding: 10px 15px;
                    font-family: $base-font;
                    cursor: pointer;
                    &:focus {
                        outline: none;
                    }
                    &:disabled {
                        color: $gray-3;
                        cursor: default;
                    }
                    &.Table__pageButton--active {
                        background-color: $success-color;
                        color: #fff;
                    }
                }
                &.Table__prevPageWrapper,
                &.Table__nextPageWrapper {
                    button:not(:disabled) {
                        color: $success-color;
                    }
                }
            }
        }
    }
    .subrow {
        word-wrap: break-word;
        padding: 30px 20px;
        background-color: #e4f0f7;
        line-height: 30px;
    }
}

.table-scroll-body {
    .ReactTable {
        .rt-tbody {
            max-height: 600px;
            overflow-y: scroll;
        }
    }
}

.table-group .rt-th {
    border-right: 1px solid rgba(0,0,0,0.05)!important;
    display: grid!important;
}
