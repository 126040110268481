$base-color: #5a5e67;
$dark-color: #141927;
$base-font: 'Montserrat', 'Open Sans', sans-serif;

$gray-1: #f8f8f9;
$gray-2: #f6f7fa;
$gray-3: #b8babe;
$gray-4: #9ea1aa;

$success-color: #31cb9e;
$danger-color: #f96363;
$warning-color: #edab2f;
$primary-color: #1e89c1;