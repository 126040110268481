.timezone-picker {
    display: inline-block;
    font: 13px sans-serif;
    position: relative;
}
.timezone-picker input {
    width: 100%;
    padding: 9px 12px;
    font: inherit;
    box-sizing: border-box;
    outline: 0;
    background: #fff;
    border: 1px solid #e6ebec;
    border-radius: 2px;
    color: #474747;
}
.timezone-picker ul {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    border: 1px solid #e6ebec;
    margin-top: -1px;
    border-radius: 0 0 3px 3px;
    display: none;
}
.timezone-picker button {
    color: #444;
    padding: 5px 12px;
    cursor: pointer;
    outline: none;
    display: block;
    border: 0;
    width: 100%;
    text-align: left;
    border-radius: 0;
    font: inherit;
}
.timezone-picker button.focus {
    background: #f0f0f0;
}
.timezone-picker ul.open {
    display: block;
}
