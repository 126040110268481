.rt-tbody {
    overflow-x: hidden !important;
}

@media only screen and (max-width: 600px) {
    .mobile-mb-xl {
        margin-bottom: 40px;
    }

    .mobile-mb-l {
        margin-bottom: 25px;
    }

    .mobile-mb-s {
        margin-bottom: 15px;
    }

    .mobile-mr-s {
        margin-right: 15px;
    }

    .w-sm-100 {
        width: 100% !important;
    }

    .ml-mb-0 {
        margin-left: 0 !important;
    }

    .my-mb-3 {
        margin: 0.5rem 0;
    }
}

@media only screen and (min-width: 600px) {
    .rfq-params-filter {
        width: 77%;
        display: flex !important;
    }

    .big-screen-h100 {
        height: 100% !important;
    }
}

.setting-title {
    color: #141927;
    margin: 0;
    padding-bottom: 13px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    line-height: 30px;
}

.c-json-view {
    background-color: #fff;
}

.c-json-key {
    font-size: 14px !important;
    color: rgb(0, 43, 54) !important;
    letter-spacing: 0.5px !important;
    vertical-align: top !important;
    opacity: 0.85 !important;
    ;
}

.c-json-string {
    color: rgb(203, 75, 22) !important;
}

.c-json-outter {
    margin-bottom: 3px !important;
    margin-left: 33px;
}

.bg-is-new {
    background-color: rgb(200, 255, 200) !important;
}

.bg-is-delete {
    background-color: rgb(255, 200, 200) !important;
}

.dashed-border {
    border: 1px dashed blue;
}
