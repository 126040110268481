.form-control{
	font-size: 14px;
	&:focus{
		box-shadow: none;
	}
}
input, button{
	font-family: $base-font;
}
.form-group{
	.form-control{
		padding-top: 9px;
		padding-bottom: 9px;
		height: auto;
	}
}
.btn{
	&.btn-lg{
		font-size: 16px;
    padding: 10px 26px;
	}
	&:focus{
		box-shadow: none;
	}
}
.dropdown-toggle::after{
	display: inline-block;
	content: '';
	width: 7px;
	height: 7px;
	border: solid #5d616a;
	border-width: 0px 2px 2px 0;
	transform: rotate(45deg);
	margin-left: 15px;
}
.container-fluid, .navbar{
	padding-left: 30px;
	padding-right: 30px;
}
.text-success{ color: $success-color!important; }
.text-danger{ color: $danger-color!important; }
.text-warning{ color: $warning-color!important; }

.btn{
	&.btn-primary{ 
		background-color: $primary-color; 
		border-color: $primary-color; 
	}
	&.btn-danger{ 
		background-color: $danger-color; 
		border-color: $danger-color; 
	}
	&.btn-success{ 
		background-color: $success-color; 
		border-color: $success-color; 
	}
}

.modal-header{
	padding: 16px 24px;
} 
.modal-body{
	padding: 16px 24px 48px;
}
.modal-title{
	*{
		margin-right: 10px;
		vertical-align: middle;
	}
	img{
		width: 32px;
		height: 32px;
	}
}
@media (min-width: 992px){
	.modal-lg {
		max-width: 900px;
	}
}


// For pretty checkbox
.panel{
	.pretty{
		input:checked ~ .state.p-success{
			label:after, .pretty.p-toggle .state.p-success label:after{
				background-color: $success-color !important;
			}
		}
	}
}
// End css for pretty checkbox

// For react-select
.css-vj8t7z, .css-2o5izw{
	border-radius: 1px!important;
	padding: 5px 0px;
	cursor: pointer!important;
}
.css-2o5izw{
	border-color: hsl(0,0%,80%)!important;
	box-shadow: none!important;
}
// End css for react-select

// Datepicker
.SingleDatePicker,
.SingleDatePickerInput,
.DateInput{
	width: 100%;
}
.DateInput{
	&_input{
		font-size: 15px;
    font-weight: 500;
		color: $base-color;
		background-image: url(../img/icon/calendar.svg);
		background-repeat: no-repeat;
    background-position: 92%;
	}
}
// End css for datepicker