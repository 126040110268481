.h-100{
  height: 100%;
}

.text-dark{color: $dark-color!important}
.text-gray-1{color: $gray-1}
.text-gray-2{color: $gray-2}
.text-gray-3{color: $gray-3}
.text-gray-4{color: $gray-4}

.bg-gray-1{background-color: $gray-1}
.bg-gray-2{background-color: $gray-2}
.bg-gray-3{background-color: $gray-3}
.bg-gray-4{background-color: $gray-4}

.semi-b{
  font-weight: 600;
}
.text-size-0{
  font-size: 14px;
}
.text-size-1{
  font-size: 15px;
}
.text-size-2{
  font-size: 16px;
}
.text-size-3{
  font-size: 17px;
}
.text-size-4{
  font-size: 18px;
}
.text-size-s-1{
  font-size: 12px;
}
.text-size-s-2{
  font-size: 11px;
}
.text-size-s-3{
  font-size: 10px;
}
.pointer{
  cursor: pointer;
}
.text-hide-more{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn{
  &.btn-default{
    border: 1px solid #ddd;
  }
  &.btn-label{
    font-size: 11px;
    padding: .25rem .5rem;
    line-height: 1.5;
    border-radius: .2rem;
    min-width: 76px;
  }
}
.panel{
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
  // overflow: hidden;
  p{
    word-wrap: break-word;
  }
  &:not(&-table){
    .panel-title{
      border-bottom: 2px solid $gray-2;
    }
  }
  .panel-header{
    padding: 1vh 1.5vw 0 1.5vw;
    .panel-title{
      color: $dark-color;
      margin: 0;
      padding-bottom: 13px;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 600;
      position: relative;
      display: flex;
      align-items: center;
      line-height: 30px;
      .panel-tool{
        text-transform: none;
        font-weight: 500;
        position: absolute;
        right: 0;
        select, input{
          font-family: $base-font;
        }
      }
    }
  }
  .panel-body{
    padding-top: 0;
    padding-bottom: 23px;
  }
  .panel-row{
    padding: 1.2vh 1.2vw;
    &:nth-child(even){
      background-color: $gray-2;
    }
    p:last-child{
      margin-bottom: 0;
    }
  }
  &-table{
    .panel-header{
      padding-bottom: 23px;
    }
  }
}
.k-progress{
  margin-bottom: 50px;
  .k-progress-bar{
    height: 2px;
    background-color: #ddd;
    position: relative;
  }
  .k-progress-value{
    height: 4px;
    background-color: $warning-color;
    border-radius: 5px;
    position: relative;
    top: -1px;
    transition: width 0.2s
  }
  .k-progress-value-active{
    height: 4px;
    background-color: $success-color;
    border-radius: 5px;
    position: relative;
    top: -5px;
    transition: width 0.2s;
  }

  .k-progress-warnning{
    height: 4px;
    background-color: $danger-color;
    border-radius: 5px;
    position: relative;
    top: -5px;
    transition: width 0.2s;
  }


  .k-progress-target{
    position: absolute;
    background-color: $success-color;
    color: #fff;
    border-radius: 3px;
    top: 18px;
    left: 80%;
    transform: translateX(-50%);
    z-index: 1;
    &::after, &::before{
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      transform: translateY(-50%) translateX(-50%);
      left: 50%;
    }
    &::after{
      width: 6px;
      height: 6px;
      top: -17px;
      background-color: #fff;
    }
    &::before{
      width: 12px;
      height: 12px;
      top: -17px;
      background-color: $success-color;
    }
    .k-progress-label{
      font-weight: 300;
      width: 150px;
      padding: 6px 12px;
      &::after{
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $success-color;
        top: -5px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
    }
  }
}


$sm: '576px';
$md: '768px';
$lg: '992px';
$xl: '1200px';
